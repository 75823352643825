var Abandonment = { TypeId: 1, Name: "Abandono Posto de Trabalho" };
var MaternityOrPaternity = {
  TypeId: 2,
  Name: "Baixa Maternidade / Paternidade",
};
var Health = { TypeId: 3, Name: "Baixa Prolongada" };
var Resignation = { TypeId: 4, Name: "Demissão" };
var Reform = { TypeId: 5, Name: "Reforma" };
var Termination = { TypeId: 6, Name: "Rescisão" };
var LaborUnion = { TypeId: 7, Name: "Sindicato" };
var Accident = { TypeId: 8, Name: "Sinistro" };

export const UserAbsenceTypes = [
  Abandonment,
  MaternityOrPaternity,
  Health,
  Resignation,
  Reform,
  Termination,
  LaborUnion,
  Accident,
];

export function GetTypeNameById(id) {
  for (let index = 0; index < UserAbsenceTypes.length; index++) {
    if (UserAbsenceTypes[index].TypeId === id) {
      return UserAbsenceTypes[index].Name;
    }
  }
  return "";
}
