<template>
  <section v-if="user" id="UsersDetail">
    <app-page-title>
      <v-layout row justify-space-between align-end>
        <v-flex>
          <h3 class="size--s12 text-red lighten-3 text-left mb-2">
            {{ roleName }}
          </h3>
          <h1>{{ user.Name }}</h1>
          <v-chip label color="green white--text darken-1" v-show="user.Code">
            {{ user.Code }}
          </v-chip>
          <v-chip label color="blue white--text darken-1" v-show="user.Email">
            {{ user.Email }}
          </v-chip>
        </v-flex>
        <v-flex class="text-right">
          <v-btn
            color="info"
            class="ml-auto mr-2"
            :to="'edit'"
            v-show="!isEditView"
          >
            Editar
            <i class="icon-arrow-right ml-2"></i>
          </v-btn>
          <v-menu offset-y bottom left>
            <template v-slot:activator="{ on }">
              <v-btn flat icon color="white" v-on="on">
                <v-icon>more_horiz</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-tile @click="removeUser">
                <v-list-tile-title class="px-3"
                  >Eliminar Utilizador</v-list-tile-title
                >
              </v-list-tile>
            </v-list>
          </v-menu>
          <!-- <v-btn
            color="info"
            class="ml-auto"
            @click="removeUser"
            v-show="!isEditView"
          >
            Apagar
            <i class="icon-arrow-right ml-2"></i>
          </v-btn> -->
        </v-flex>
      </v-layout>
    </app-page-title>
    <div class="d-flex align-content-stretch detail-wrapper">
      <div class="detail-sidebar">
        <app-sidebar-menu :menus="menus" />
      </div>
      <div class="detail-content">
        <router-view
          params="route: route"
          :user="user"
          @instanceUpdated="ReloadUser"
        />
      </div>
    </div>
  </section>
</template>
<script>
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import usersService from "@/services/api/usersService";
import SidebarComponent from "@/components/layout/page-menu";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import * as kendo from "@progress/kendo-ui";

export default {
  components: {
    "app-sidebar-menu": SidebarComponent,
  },
  data() {
    return {
      user: null,
      menus: [
        {
          Name: "Dashboard",
          Icon: "icon-home",
          Path: "dashboard",
        },
        {
          Name: "Histórico",
          Icon: "icon-list",
          Path: "courses",
        },
        { Name: "Perfil", Icon: "icon-workflow", Path: "profile" },
        { Name: "Segurança", Icon: "icon-lock", Path: "security" },
        { Name: "Documentos", Icon: "icon-help", Path: "documents" },
        // { Name: "Ausências", Icon: "icon-reports", Path: "absences" },
        { Name: "Logs", Icon: "icon-reports", Path: "reports" },
      ],
    };
  },
  watch: {
    "$route.params.idUser"() {
      this.LoadUser(this.$route.params.idUser);
    },
  },
  computed: {
    ...mapGetters({
      userRoles: types.GET_USER_ROLES,
    }),
    isEditView() {
      return this.$route.path.indexOf("/edit") >= 0;
    },
    roleName() {
      var role =
        this.userRoles &&
        this.userRoles.find((s) => s.IdUserRole == this.user.IdUserRole);
      return role ? role.Name : "";
    },
  },
  created() {
    this.LoadUser(this.$route.params.idUser);
  },
  methods: {
    async ReloadUser() {
      this.LoadUser(this.$route.params.idUser);
    },
    async LoadUser(id) {
      await usersService
        .GetSingle(id, { Detailed: true })
        .then((response) => (this.user = response.data.Instance))
        .catch(() => {
          notificationServiceBus.showErrorMessage(
            "Erro!",
            "ocorreu um erro inesperado! Tente novamente."
          );
        });
    },
    async removeUser() {
      kendo.confirm("Tem a certeza que quer apagar pergunta?").done(() => {
        usersService
          .Delete(this.$route.params.idUser)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou a resposta com sucesso!"
            );

            this.$router.push("/users");
          })
          .catch((error) => {
            this.isLoading = false;
            notificationServiceBus.showError(error.response);
          });
      });
    },
  },
};
</script>
