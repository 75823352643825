<template>
  <v-container grid-list v-if="user">
    <h4 class="text-uppercase text--darkgray mb-4">Documentos</h4>
    <div class="k-grid k-grid-toolbar">
      <app-form-modal
        class-icon="icon-add"
        :title-dialog="`${isNew ? 'Adicionar' : 'Editar'} Documento`"
        :confirmAction="saveInstance"
        :cancelAction="clearData"
        :formValid="!$v.Instance.$error"
        ref="modal"
      >
        <v-layout wrap>
          <v-flex xs12 pr-2>
            <v-text-field
              required
              v-model="Instance.Title"
              label="Nome"
              :error="$v.Instance.Title.$error"
              @blur="$v.Instance.Title.$touch()"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs6 pr-2>
            <app-date-picker
              v-model="Instance.DatePublished"
              title="Data Início"
              required
              :error="$v.Instance.DatePublished.$error"
              @blur="$v.Instance.DatePublished.$touch()"
            ></app-date-picker>
          </v-flex>
          <v-flex xs6 pl-2>
            <v-select
              :items="categories"
              label="Categoria"
              item-text="Title"
              item-value="IdUserDocumentCategory"
              v-model="Instance.IdUserDocumentCategory"
            ></v-select>
          </v-flex>
          <v-flex>
            <kendo-upload
              ref="upload"
              :multiple="false"
              name="file"
              :select="onSelectFile"
            ></kendo-upload>
          </v-flex>
        </v-layout>
      </app-form-modal>
    </div>
    <Grid
      :style="{ height: 'auto' }"
      :data-items="Items"
      :columns="Columns"
      :pageable="true"
      :skip="skip"
      :take="take"
      :total="grid.pagging.total"
      @pagechange="pageChange"
      @edit="edit"
      @remove="remove"
    >
      <template slot="downloadCell" slot-scope="{ props }">
        <td :class="props.className">
          <a :href="getRelativePath(props.dataItem)" target="_blank"
            >Download</a
          >
        </td>
      </template>
      <GridNoRecords>Não existe documentos</GridNoRecords>
    </Grid>
  </v-container>
</template>
<script>
import * as kendo from "@progress/kendo-ui";
import { required, minLength } from "vuelidate/lib/validators";
import FormModalComponent from "@/components/modals/form-modal";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import userDocumentsService from "@/services/api/userDocumentsService";
import EditCommandCell from "@/components/grid/edit-command.js";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import DatePickerComponent from "@/components/forms/date-picker";
import GridHelper from "@/utilities/grid";
import UploadHelper from "@/utilities/upload";
import Dates from "@/utilities/dates";
import userDocumentsCategoriesService from "@/services/api/userDocumentsCategoriesService";
import UrlBuider from "@/utilities/url-builder";
export default {
  components: {
    "app-date-picker": DatePickerComponent,
    "app-form-modal": FormModalComponent,
  },
  props: { user: { type: Object, default: null } },
  data() {
    return {
      categories: [],
      Instance: { Title: "" },
      Items: [],
      grid: {
        pagging: {
          pageId: 1,
          pageSize: 10,
          total: 0,
        },
      },
      Columns: [
        {
          field: "Title",
          title: "Nome",
        },
        {
          field: "DatePublished",
          title: "Data de publicação",
        },
        {
          field: "FilenameSrc",
          title: "Ficheiro",
          cell: "downloadCell",
        },
        {
          cell: EditCommandCell,
          width: 75,
          title: "",
        },
        {
          cell: DeleteCommandCell,
          width: 75,
          title: "",
        },
      ],
    };
  },
  validations: {
    Instance: {
      Title: { required, minLength: minLength(3) },
      DatePublished: { required },
      IdUserDocumentCategory: { required },
      File: { required },
    },
  },
  computed: {
    isNew() {
      return this.Instance != null && this.Instance.IdUserDocument == 0;
    },
    skip() {
      return (this.grid.pagging.pageId - 1) * this.grid.pagging.pageSize;
    },
    take() {
      return this.grid.pagging.pageSize;
    },
  },
  async created() {
    this.resetInstance();
    this.loadCategories();
    await this.loadItems();
  },
  methods: {
    getRelativePath(item) {
      return UrlBuider.GetPath(item.FilenameSrc);
    },
    onSelectFile(evt) {
      UploadHelper.LoadFile(evt)
        .then((file) => (this.Instance.File = file))
        .catch((file) => (this.Instance.File = file));
    },
    async loadCategories() {
      await userDocumentsCategoriesService
        .Get({ Filters: [] })
        .then((res) => {
          this.count = res.data.Total;
          this.categories = res.data.Items;
        })
        .catch(() => {
          this.count = 0;
          this.categories = [];
        });
    },
    openModal() {
      this.$refs.modal.open();
    },
    clearData(callback) {
      this.resetInstance();
      callback(true);
    },
    edit(item) {
      this.Instance = { ...item.dataItem };
      this.openModal();
    },
    remove(item) {
      this.deleteInstance(item.dataItem);
    },
    pageChange(ev) {
      this.grid.pagging.pageId = ev.page.skip / ev.page.take + 1;
      this.readUserLogs();
    },
    async loadItems() {
      let options = GridHelper.ConvertOptionsFromNativeKendoUIGrid(
        this.grid.pagging
      );
      let data = GridHelper.ParseOptions(options);
      data.Filters = [];
      await userDocumentsService
        .Get(data)
        .then((res) => {
          this.Items = res.data.Items.filter(
            (s) => s.IdUser == this.user.IdUser
          );
          this.grid.pagging.total = res.data.Total;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    resetInstance() {
      var dt = new Date();
      this.Instance = {
        IdUserDocument: 0,
        DatePublished: Dates.PresentToDate(dt),
        IdUserDocumentCategory: 0,
        IdUser: this.user.IdUser,
      };
    },
    async saveInstance(callBack) {
      if (this.isNew) {
        await userDocumentsService
          .Create(this.Instance)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Adicionou o ficheiro com sucesso!"
            );
            this.loadItems();
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      } else {
        await userDocumentsService
          .Update(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou o ficheiro com sucesso!"
            );
            const index = this.Items.findIndex(
              (s) => s.IdUserDocument == this.Instance.IdUserDocument
            );
            if (index >= 0) {
              this.Items[index] = { ...response.data.Instance };
            }
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      }
    },
    deleteInstance(item) {
      var id = item.IdUserDocument;
      kendo.confirm("Tem a certeza que quer apagar a ausência?").done(() => {
        userDocumentsService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou ficheiro com sucesso!"
            );
            this.loadItems();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
  },
};
</script>

<style scoped></style>
