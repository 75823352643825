<template>
  <v-container>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-layout row align-center>
            <v-flex xs12 sm3 class="border-right-light">
              <v-layout row wrap class="p-4">
                <v-flex xs12 class="d-flex align-items-center pb-2 border-bottom-light">
                  <div>
                    <h1>Formação</h1>
                    <p>Total de formações inscrito</p>
                  </div>
                  <span class="text-right text-secondary text-bolder size--s18 mr-1">{{ this.user.Subscriptions.length }}</span>
                </v-flex>
                <!-- <v-flex xs12 class="d-flex align-items-center pt-2">
                  <div>
                    <h1>Gamification</h1>
                    <p>Total de pontos gaming</p>
                  </div>
                  <span
                    class="text-right text-primary text-bolder size--s18 mr-1"
                    >{{ this.user.GamificationPoints }}</span
                  >
                </v-flex> -->
              </v-layout>
            </v-flex>
            <v-flex xs12 sm9 class="p-4">
              <h4 class="text-uppercase text--darkgray mb-4" v-if="ProgressionProfile">
                Percurso Formativo » {{ progress }} de
                {{ ProgressionProfile.HoursObjective }} Horas
              </h4>
              <div class="progress__box">
                <v-progress-linear :value="progress" height="7" :color="progressColor"></v-progress-linear>
                <span class="text-completed" v-bind:style="{ left: this.hoursRequiredPct + '%' }" v-if="this.ProgressionProfile != null">
                  {{ this.hoursRequired }}</span>
                <span class="text-objective" v-if="this.ProgressionProfile != null">
                  {{ this.ProgressionProfile.HoursObjective }}</span>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <div v-if="!isLoading" id="FormationProfile">
      <section class="timeline">
        <v-layout row wrap align-start>
          <v-flex xs3></v-flex>
          <v-flex xs9 class="line text--darkgray">
            <h6 class="size--s18 text-uppercase ball--first mb-5">
              Percurso Formativo
            </h6>
          </v-flex>
        </v-layout>
        <div v-if="isFirstYear">
          <v-layout v-for="item in firstYearData" :key="item.title" row wrap align-start>
            <v-flex xs3 class="text-center">
              <div class="timeline--icon">
                <i class="icon-flag"></i>
              </div>
            </v-flex>
            <v-flex xs9 class="line text--darkgray pb-3">
              <h6 class="size--s18 ball">{{ item.title }}</h6>
              <app-timeline-item v-for="(item, index) in item.array" :key="`${item.title}»${index}`" :course="item.Course" :user="user" :label="item.Portfolio.Name"></app-timeline-item>
            </v-flex>
          </v-layout>
        </div>
        <v-layout row wrap align-start>
          <v-flex xs3 class="text-center">
            <div class="timeline--icon">
              <i class="icon-flag"></i>
            </div>
          </v-flex>
          <v-flex xs9 class="line text--darkgray pb-3">
            <h6 class="size--s18 ball">Complementar</h6>
            <app-timeline-item v-for="(item, index) in complementaryCourses" :key="`Complementary»${index}`" :course="item.Course" :user="user" :label="item.Portfolio.Name"></app-timeline-item>
          </v-flex>
        </v-layout>
      </section>
    </div>
    <v-alert v-else :value="true" type="info" class="mt-2">
      Aguarde por favor
    </v-alert>
  </v-container>
</template>

<script>
import usersService from "@/services/api/usersService";
import TimeLineItemComponent from "@/components/timelines/timeline-item";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import Dates from "@/utilities/dates";

export default {
  components: {
    "app-timeline-item": TimeLineItemComponent,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    firstYearData () {
      return [
        { title: "Integração", array: this.basicCourses },
        { title: "Dos 1 aos 3 meses", array: this.shortCourses },
        { title: "Dos 3 aos 6 meses", array: this.mediumCourses },
        { title: "Contínua (+6 meses)", array: this.longCourses },
      ];
    },
    progressColor () {
      return this.progress >= this.hoursRequiredPct ? "light-green" : "red";
    },
    basicCourses () {
      return this.isFirstYear ? this.getCourses("Basic") : [];
    },
    shortCourses () {
      return this.isFirstYear ? this.getCourses("Short") : [];
    },
    mediumCourses () {
      return this.isFirstYear ? this.getCourses("Medium") : [];
    },
    longCourses () {
      return this.isFirstYear ? this.getCourses("Long") : [];
    },
    complementaryCourses () {
      return this.getCourses("Complementary");
    },
    hoursRequiredPct () {
      var date = new Date(new Date().getFullYear(), 0, 1);
      var days = this.daysDiff(date, new Date());
      return parseInt((days / 365) * 100);
    },
    hoursRequired () {
      return parseInt(
        (this.hoursRequiredPct / 100) * this.ProgressionProfile.HoursObjective
      );
    },
    isFirstYear () {
      if (!this.user.DateAdmission) {
        return false;
      }
      return (
        Dates.GetDaysDifference(
          Dates.ConvertToDate(this.user.DateAdmission),
          new Date()
        ) < 365
      );
    },
    progress () {
      return this.ProgressionProfile
        ? parseInt(this.ProgressionProfile.HoursCompleted)
        : 0;
    },
  },
  data () {
    return {
      isLoading: true,
      ProgressionProfile: null,
    };
  },
  methods: {
    daysDiff (date1, date2) {
      var t2 = date2.getTime();
      var t1 = date1.getTime();
      return parseInt((t2 - t1) / (24 * 3600 * 1000));
    },
    getCourses (field) {
      var step = this.ProgressionProfile.Steps.find((s) => s.Name === field);
      return step ? step.Items : [];
    },
    async loadUserFormativePorgression () {
      await usersService
        .GetUserFormativePorgression(this.user.IdUser)
        .then((res) => {
          this.ProgressionProfile = res.data;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  async created () {
    await this.loadUserFormativePorgression();
  },
};
</script>

<style scoped lang="scss">
@import "../../../../styles/variables.scss";

p {
  margin-bottom: 0;
}

.progress-bar-txt {
  position: relative;
  font-size: 14px;
  top: -22px;
  left: 6px;
  z-index: 1;
}

.border-right-light {
  border-right: 1px solid $light;
}

.border-bottom-light {
  border-bottom: 1px solid $light;
}

.photo {
  display: block;
  max-width: 150px;
}

#FormationProfile {
  max-width: 900px;
  display: block;

  .timeline {
    max-width: unset;
    display: block;
  }
}

.progress__box {
  margin-top: 20px;
  position: relative;
  border: 1px solid $sidebar-link-color;
  padding: 30px 30px 0 30px;

  span.text-objective {
    font-size: 16px;
    font-family: $font-family-base;
    color: $gray-950;
    display: block;
    text-align: right;
    position: absolute;
    right: 20px;
    top: 15px;
    max-width: 20px;
  }

  span.text-completed {
    font-size: 16px;
    font-family: $font-family-base;
    color: $gray-950;
    display: block;
    position: relative;
    top: -55px;
    max-width: 20px;

    &::after {
      content: "";
      height: 25px;
      width: 2px;
      background-color: $gray-700;
      position: absolute;
      bottom: -27px;
      left: 5px;
      z-index: 4;
      margin: auto;
    }
  }

  .progress__box--timer {
    width: 36px;
    margin-left: -17px;
  }
}

.timeline {
  position: relative;
  max-width: 750px;
  margin-top: 66px;

  .timeline__end {
    margin-top: 45px;
    position: relative;
    display: flex;
    justify-content: space-between;

    span {
      font-size: 14px;
      font-family: $font-family-base;
    }

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #f00;
      position: absolute;
      left: -52px;
      top: -45px;
    }

    &::before {
      position: relative;
      content: "\E91C";
      font-family: "icomoon" !important;
      display: flex;
      font-size: 26px;
      align-items: center;
      width: 57px;
      height: 57px;
      border-radius: 50%;
      border: 1px solid;
      position: absolute;
      left: -68px;
      padding-left: 15px;
      bottom: -25px;
    }
  }

  .timeline--icon {
    i {
      font-size: 34px;
      color: lighten($color: $primary, $amount: 40%);
    }
  }

  h6 {
    margin-bottom: 15px;
  }

  .line {
    position: relative;

    &::before {
      content: "";
      width: 8px;
      position: absolute;
      left: -45px;
      display: block;
      height: 100%;
      background-color: $gray-300;
    }
  }

  .ball {
    position: relative;

    &--first::after {
      content: "";
      position: absolute;
      top: -2px;
      left: -48px;
      display: block;
      background-color: $primary;
      border-radius: 15px;
      width: 15px;
      height: 15px;
    }

    &::after {
      content: "";
      position: absolute;
      top: -2px;
      left: -48px;
      display: block;
      background-color: $white;
      border: 1px dashed $primary;
      border-radius: 15px;
      width: 15px;
      height: 15px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 6px;
      left: -46px;
      display: block;
      width: 25px;
      height: 2px;
      background-color: $primary;
    }
  }

  .timeline__box {
    position: relative;
    display: block;
    color: #b5b5b5;

    &.border-left {
      border-left: 5px solid !important;
      border-color: #bcc0cd !important;

      &.b-green {
        border-color: $green !important;

        .timeline__box--icon {
          border-color: $green !important;

          i {
            color: $green;
          }
        }
      }

      &.b-red {
        border-color: lighten($color: $primary, $amount: 40%) !important;

        .timeline__box--icon {
          border-color: lighten($color: $primary, $amount: 40%) !important;

          i {
            color: $primary;
          }
        }
      }
    }

    &--icon {
      display: flex;
      text-align: center;
      align-items: center;
      width: 57px;
      height: 57px;
      border-radius: 50%;
      border: 1px solid;

      i {
        margin: 0 auto;
      }
    }
  }
}
</style>
