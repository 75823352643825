<template>
  <div class="container__custom--w800">
    <v-layout row>
      <v-flex xs12>
        <v-form @submit.prevent="saveInstance">
          <v-card>
            <v-card-title primary-title>
              <h3 class="headline mb-0">Alteração de dados pessoais</h3>
            </v-card-title>
            <v-card-text>
              <app-users-selector :parent="Parent" @userSelected="onUserSelected"></app-users-selector>

              <v-text-field v-model="Instance.Code" :error="$v.Instance.Code.$error" @blur="$v.Instance.Code.$touch()" label="Código" required></v-text-field>
              <v-text-field v-model="Instance.Name" :error="$v.Instance.Name.$error" @blur="$v.Instance.Name.$touch()" label="Nome" required></v-text-field>
              <v-text-field v-model="Instance.FullName" label="Nome Completo" required></v-text-field>
              <v-text-field v-model="Instance.Email" :error="$v.Instance.Email.$error" @blur="$v.Instance.Email.$touch()" label="Email"></v-text-field>
              <v-text-field v-model="Instance.NISS" label="NISS"></v-text-field>
              <v-text-field v-model="Instance.FiscalIdNumber" label="NIF"></v-text-field>
              <v-text-field v-model="Instance.WeekHours" label="Horas semanais"></v-text-field>
              <v-select v-model="Instance.IdUserRole" :items="userRoles" label="Role" item-text="Name" item-value="IdUserRole" required></v-select>
              <v-select v-model="Instance.IdUserGroup" :items="userGroups" label="Grupo Principal" item-text="Name" item-value="IdUserGroup" :clearable="true" :required="false"></v-select>
              <v-select v-model="Instance.UserGroupsIds" :items="userGroups" label="Grupos Secundários" item-text="Name" item-value="IdUserGroup" :clearable="true" :required="false" multiple></v-select>
              <v-select v-if="this.user.IdUserRole === 2" v-model="Instance.IdSupplier" :items="suppliers" label="Entidade Fornecedora" item-text="Name" item-value="IdSupplier" :clearable="true" :required="false"></v-select>
              <app-uploaders-image :async="false" @onSuccess="imageSelected">
              </app-uploaders-image>
              <v-radio-group v-model="Instance.IsActive" label="Activo?" :row="true" :required="true">
                <v-radio label="Sim" :value="true"></v-radio>
                <v-radio label="Não" :value="false"></v-radio>
              </v-radio-group>
              <v-layout row wrap align-content-center>
                <v-flex xs12 md6 pr-1>
                  <app-date-picker v-model="Instance.DateAdmission" title="Data de admissão"></app-date-picker>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 md6 pl-1>
                  <app-date-picker v-model="Instance.DateRescission" title="Data de Rescisão"></app-date-picker>
                </v-flex>
              </v-layout>
              <v-checkbox v-model="Instance.MarketingApproved" label="Autorizo a utilização dos meus dados de email, telefone e morada para envio de newsletters, revistas, formações e outras informações institucionais da APDP."></v-checkbox>
              <!-- <v-checkbox
                v-model="Instance.AuthNotifications"
                label="Utilizador consentiu a utilização de dados pessoais para
                  gestão da inscrição"
              ></v-checkbox> -->
            </v-card-text>
            <v-card-actions>
              <v-btn :loading="isSaving" type="submit" color="accent" class="ml-auto">Confirmar</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import { required, email } from "vuelidate/lib/validators";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import usersService from "@/services/api/usersService";
import UsersSelectorComponent from "@/components/selectors/users";
import ImageUploaderComponent from "@/components/forms/uploaders/image";
import SupplierService from "@/services/api/suppliersService";
import DatePickerComponent from "@/components/forms/date-picker";

export default {
  props: { user: { type: Object, default: null } },
  components: {
    "app-users-selector": UsersSelectorComponent,
    "app-uploaders-image": ImageUploaderComponent,
    "app-date-picker": DatePickerComponent,
  },
  data () {
    return {
      Parent: null,
      suppliers: null,
      Instance: null,
      isSaving: false,
    };
  },
  validations: {
    Instance: {
      Name: {
        required,
      },
      Email: {
        email,
      },
      Code: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      userGroups: types.GET_USER_GROUPS,
      userRoles: types.GET_USER_ROLES,
    }),
  },
  async created () {
    this.getSuppliers();
    this.Instance = { ...this.user };
    if (this.user.IdParent) {
      this.LoadParent();
    }
  },
  watch: {
    user: function () {
      if (this.user.IdParent) {
        this.LoadParent();
      }
    },
  },
  methods: {
    async getSuppliers () {
      await SupplierService.Get()
        .then((res) => {
          this.suppliers = res.data.Items;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    onUserSelected (id) {
      this.Instance.IdParent = id ? id : 0;
    },
    imageSelected (file) {
      this.Instance.Photo = file;
    },
    async LoadParent () {
      await usersService
        .GetSingle(this.user.IdParent, { Detailed: false })
        .then((response) => (this.Parent = response.data.Instance))
        .catch(() => {
          notificationServiceBus.showErrorMessage(
            "Erro!",
            "ocorreu um erro inesperado! Tente novamente."
          );
        });
    },
    async saveInstance () {
      await usersService
        .Update(this.Instance)
        .then(() => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "Alterou os dados com sucesso!"
          );
          this.$emit("instanceUpdated");
        })
        .catch((error) => {
          notificationServiceBus.showErrorMessage(
            "Erro!",
            "Erro a editar os dados!" + error.response
          );
        });
    },
  },
};
</script>

<style scoped></style>
