<template>
  <v-container v-if="user" grid-list-lg>
    <v-card class="mb-5">
      <v-card-title primary-title>
        <h3 class="headline mb-0">Detalhes Pessoais</h3>
      </v-card-title>
      <v-card-text>
        <v-layout row wrap grid-list>
          <v-flex xs6>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>Código</v-list-tile-title>
                <v-list-tile-sub-title>{{ user.Code }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-flex>
          <v-flex xs6>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>Nome</v-list-tile-title>
                <v-list-tile-sub-title
                  >{{ user.Name ? user.Name : "-" }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-flex>
          <v-flex xs6>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>Nome Completo</v-list-tile-title>
                <v-list-tile-sub-title
                  >{{ user.FullName ? user.FullName : "-" }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-flex>
          <v-flex xs6
            ><v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>Email</v-list-tile-title>
                <v-list-tile-sub-title>{{
                  user.Email ? user.Email : "-"
                }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-flex>
          <v-flex xs6
            ><v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>NIF</v-list-tile-title>
                <v-list-tile-sub-title>{{
                  user.FiscalIdNumber ? user.FiscalIdNumber : "-"
                }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-flex>
          <v-flex xs6
            ><v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>NISS</v-list-tile-title>
                <v-list-tile-sub-title>{{
                  user.NISS ? user.Niss : "-"
                }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-flex>
          <v-flex xs6>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>Role</v-list-tile-title>
                <v-list-tile-sub-title>{{ Role }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-flex>
          <v-flex xs6>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>Grupo</v-list-tile-title>
                <v-list-tile-sub-title>{{ UserGroup }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-flex>
          <v-flex xs6>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>Activo?</v-list-tile-title>
                <v-list-tile-sub-title>{{ IsActive }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-flex>
          <v-flex xs6>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>Data de Admissão</v-list-tile-title>
                <v-list-tile-sub-title>{{
                  !user.DateAdmission ? "-" : user.DateAdmission
                }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-flex>
          <v-flex xs6>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>Horas Semanais</v-list-tile-title>
                <v-list-tile-sub-title>{{
                  user.WeekHours
                }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-flex>
          <v-flex xs6>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>Data de Rescisão</v-list-tile-title>
                <v-list-tile-sub-title>{{
                  !user.DateRescission ? "-" : user.DateRescission
                }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-flex>
          <v-flex xs12>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>
                  Utilizador consentiu a utilização de dados pessoais para
                  divulgação e comunicação
                </v-list-tile-title>
                <v-list-tile-sub-title v-if="user.MarketingApproved">
                  Sim
                </v-list-tile-sub-title>
                <v-list-tile-sub-title v-else> Não </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-flex>
          <v-flex xs12>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>
                  Utilizador consentiu a utilização de dados pessoais para
                  gestão da inscrição
                </v-list-tile-title>
                <v-list-tile-sub-title v-if="user.AuthNotifications">
                  Sim
                </v-list-tile-sub-title>
                <v-list-tile-sub-title v-else> Não </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-card v-if="user.UserPositions">
      <v-card-title primary-title>
        <div
          class="d-flex k-justify-content-between k-align-items-center w-100"
        >
          <h3 class="headline mb-0">Afetações</h3>
          <div class="text-right">
            <app-userpositions
              :Items="Items"
              :user="user.IdUser"
              @inscriptionsAdded="inscriptionsAdded"
            ></app-userpositions>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <div
          v-if="user.UserPositions.length > 0"
          class="card-body text-xs-left"
        >
          <Grid
            v-if="!IsLoading"
            :style="{ height: 'auto' }"
            :data-items="Items"
            :columns="headers"
            @remove="remove"
          >
            <GridNoRecords>Não existem afetações</GridNoRecords>
          </Grid>
        </div>
        <div v-else class="card-body alert alert-secondary">
          O utilizador não tem afetações atribuidas.
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import userPositionsService from "@/services/api/userPositionsService";
import SubscriptionsComponent from "@/components/grid/userpositions";
import * as types from "@/store/types";
import * as kendo from "@progress/kendo-ui";

export default {
  components: {
    "app-userpositions": SubscriptionsComponent,
  },
  props: { user: { type: Object, default: null } },
  data() {
    return {
      IsLoading: false,
      Items: null,
      headers: [
        {
          title: "Função",
          align: "left",
          sortable: false,
          field: "Function",
        },
        {
          title: "Localização",
          sortable: false,
          field: "Location",
        },
        {
          title: "Centro de custo",
          sortable: false,
          field: "CostCenter",
        },
        {
          title: "Departmento",
          sortable: false,
          field: "Department",
        },
        {
          cell: DeleteCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userGroups: types.GET_USER_GROUPS,
      userRoles: types.GET_USER_ROLES,
    }),
    IsActive() {
      return this.user.IsActive ? "Sim" : "Não";
    },
    Role() {
      if (this.user.IdUserRole > 0) {
        for (let index = 0; index < this.userRoles.length; index++) {
          if (this.userRoles[index].IdUserRole == this.user.IdUserRole) {
            return this.userRoles[index].Name;
          }
        }
      } else {
        return "Não definido";
      }
      return "Não definido";
    },
    UserGroup() {
      if (this.user.IdUserGroup > 0) {
        for (let index = 0; index < this.userGroups.length; index++) {
          if (this.userGroups[index].IdUserGroup == this.user.IdUserGroup) {
            return this.userGroups[index].Name;
          }
        }
      } else {
        return "Não definido";
      }
      return "Não definido";
    },
  },
  async created() {
    this.load();
  },
  methods: {
    deleteInstance(item) {
      var id = item.IdUserPosition;
      kendo.confirm("Tem a certeza que quer apagar o anúncio?").done(() => {
        userPositionsService.Delete(id).then(() => {
          this.load();
        });
      });
    },
    remove(item) {
      this.deleteInstance(item.dataItem);
    },
    inscriptionsAdded() {
      this.load();
    },
    async load() {
      this.IsLoading = true;
      await userPositionsService.Get(this.user.IdUser).then((response) => {
        this.Items = response.data.Items;
      });
      this.IsLoading = false;
    },
  },
};
</script>

<style scoped>
.btn-add {
  color: #fff;
}
</style>
