<template>
  <app-form-modal :title-dialog="'Adicionar Inscrição'" :titleButton="'Adicionar'" :confirmAction="confirm">
    <p>Escolha pelo menos uma das opções</p>
    <app-function-selector v-model="idJobFunction"></app-function-selector>

    <app-location-selector v-model="idJobLocation"></app-location-selector>

    <app-department-selector v-model="idJobDepartment"></app-department-selector>

    <app-costcenters-selector v-model="idJobCostcenter"></app-costcenters-selector>
  </app-form-modal>
</template>

<script>
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import userPositionsService from "@/services/api/userPositionsService";
import LocationSelectorComponent from "@/components/selectors/job-locations";
import FunctionSelectorComponent from "@/components/selectors/job-functions";
import DepartmentSelectorComponent from "@/components/selectors/job-departments";
import CostCentersSelectorComponent from "@/components/selectors/job-costcenters";
import FormModalComponent from "@/components/modals/form-modal";

export default {
  components: {
    "app-function-selector": FunctionSelectorComponent,
    "app-location-selector": LocationSelectorComponent,
    "app-department-selector": DepartmentSelectorComponent,
    "app-costcenters-selector": CostCentersSelectorComponent,
    "app-form-modal": FormModalComponent,
  },
  props: ["Items", "user"],
  data () {
    return {
      idJobFunction: 0,
      idJobCostcenter: 0,
      idJobLocation: 0,
      idJobDepartment: 0,
      idCourseSession: null,
    };
  },
  methods: {
    reset () {
      this.idJobCostcenter = 0;
      this.idJobFunction = 0;
      this.idJobLocation = 0;
      this.idJobDepartment = 0;
      this.idCourseSession = null;
    },
    onCostCenterSelected (id) {
      this.idJobCostcenter = id;
    },
    onFunctionSelected (id) {
      this.idJobFunction = id;
    },
    onLocationSelected (id) {
      this.idJobLocation = id;
    },
    onDepartmentSelected (id) {
      this.idJobDepartment = id;
    },
    async confirm (callback) {
      const item = {
        IdJobCostCenter: this.idJobCostcenter,
        IdJobFunction: this.idJobFunction,
        IdJobLocation: this.idJobLocation,
        IdJobDepartment: this.idJobDepartment,
        IdCourseSession: this.idCourseSession,
        IdUser: this.$props.user,
      };
      await userPositionsService
        .Create(item)
        .then((res) => {
          notificationServiceBus.showInfoMessage("Afetação concluida");
          this.$emit("inscriptionsAdded", res.data.Instance);
          this.reset();
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        })
        .finally(() => callback(true));
    },
  },
  created () { },
};
</script>

<style></style>
