import axios from "axios";
import { API_URL } from "@/settings";
import * as types from "@/store/types";

const baseUrl = API_URL + "api/user-position";

const instance = axios.create();

const endPoints = {
  Create: baseUrl + "/create",
  Delete: baseUrl + "/delete",
  Get: baseUrl + "/fetch",
};

// eslint-disable-next-line prettier/prettier
instance.defaults.headers.common['Authorization'] = `Bearer ${window.localStorage.getItem(types.GLOBAL_USER_TOKEN)}`
instance.defaults.headers.common["Content-Type"] = "application/json";

const actions = {
  Create(userPosition) {
    const data = {
      IdUser: userPosition.IdUser,
      IdJobLocation: userPosition.IdJobLocation,
      IdJobFunction: userPosition.IdJobFunction,
      IdJobDepartment: userPosition.IdJobDepartment,
      IdJobCostCenter: userPosition.IdJobCostCenter,
    };
    return instance.post(endPoints.Create, { Instance: data });
  },
  Get(id) {
    return instance.get(endPoints.Get + "/" + id);
  },
  async Delete(id) {
    return await instance.delete(endPoints.Delete + "/" + id);
  },
};

export default actions;
